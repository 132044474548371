<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="amount"
          label="Amount to Deposit"
          dense
          outlined
          type="number"
          min="1"
          :rules="rules.default_max_45_character_and_no_empty_rule"
          @keyup="calculation_of_time_deposit"
          @change="calculation_of_time_deposit"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          v-model="terms"
          label="Terms of Deposit"
          type="number"
          min="1"
          dense
          outlined
          :rules="rules.default_max_45_character_and_no_empty_rule"
          @keyup="calculation_of_time_deposit"
          @change="calculation_of_time_deposit"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field label="Expected Interest"
                      readonly
                      v-model="interest"
                      outlined
                      dense
        >
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field label="Maturity Date"
                      readonly
                      v-model="maturity_date"
                      outlined
                      dense
        >
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field label="Total"
                      readonly
                      v-model="total"
                      outlined
                      dense
        >
        </v-text-field>
      </v-col>


    </v-row>
  </div>
</template>
<script>

  import {mapGetters} from "vuex";
  import moment from "moment";

  export default {
    data() {
      return {

        saving: false,
        alert: false,
        alert_message: '',

        search: '',
        search_member: '',
        search_items: [],
        balance: 0,
        terms: 0,

        account_id: '',
        account_no: '',
        account_name: '',
        amount: 0,
        depositors_name: '',

        interest: '',
        total_interest_earned: 0,
        total_deposit_with_interest: 0,
        maturity_date: '',
        maturity_month: '',
        total: '',
        last_name: '',
        first_name: '',
      }
    },
    props: {
      is_less: Boolean,
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
    },
    methods: {
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      calculation_of_time_deposit() {
        var interest_percentage = 0.015
        this.total_deposit_with_interest = this.amount
        if (this.is_less) {
          interest_percentage = 0.10
          for (var i = 1; i <= (this.terms / 12); i++) {
            this.total_deposit_with_interest = parseFloat(this.total_deposit_with_interest) + (parseFloat(this.amount) * parseFloat(interest_percentage));
          }
        } else {
          for (var i = 1; i <= this.terms; i++) {
            this.total_deposit_with_interest = parseFloat(this.total_deposit_with_interest) + (parseFloat(this.total_deposit_with_interest) * parseFloat(interest_percentage));
          }
        }
        var final_total = Math.round(this.total_deposit_with_interest)
        var total_interest_earned_round = (final_total - this.amount)
        this.total = this.total_deposit_with_interest
        this.total_interest_earned = (this.total_deposit_with_interest - this.amount)

        this.interest = this.formatPrice(total_interest_earned_round)
        this.total = this.formatPrice(final_total)

        this.maturity_month = moment().add(this.terms, 'months').format('MMMM YYYY')
        this.maturity_date = moment().add(this.terms, 'months').format('YYYY-MM-DD')

        this.$emit('data', {
          terms: this.terms,
          amount: this.amount,
          total_interest_earned: this.total_interest_earned,
          total_deposit_with_interest: this.total_deposit_with_interest,
          maturity_date: this.maturity_date,
          maturity_month: this.maturity_month,
        })
      },
    },
  }
</script>
